import { useMemo, useEffect } from 'react';
import PropTypes from "prop-types";
import { Button } from '@mui/material';
import shadows from "@mui/material/styles/shadows";
import Stack from "@mui/material/Stack";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import api from '../../../Middleware/api/api';
import { useAuth } from '../../../services/contexts';


function EditUserDetails( {payload, setCanNext, setPayload, confirmPayload, submit, setSubmit}) {
  const { t, i18n } = useTranslation();
  const { token } = useAuth();

  useEffect(() => {
    if (submit > 0 ) document.getElementById("sub").click();
    setSubmit(0);
  }, [submit]);

  const initialValues = {
    salutation: payload?.salutation || "",
    fullName: payload?.fullName || "",
    emailAddress: payload?.emailAddress || "",
    phoneNumber: payload?.phoneNumber || "",
    dateOfBirth: payload?.dateOfBirth || "",
    street: payload?.address?.street || "",
    houseNr: payload?.address?.houseNr || "",
    city: payload?.address?.city || "",
    postalCode: payload?.address?.postalCode || ""
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        salutation: Yup.string()
          .required(t('LBLsalutationreq'))
          .label(t('LBLSalutation')),
        fullName: Yup.string()
          .required(t('LBLFullNameReq'))
          .label(t('LBLFullName')),
        emailAddress: Yup.string()
          .email(t('wrongEmail'))
          .required(t('LBLEmailReq'))
          .required(t('LBLEmailReq'))
          .label("E-Mail"),
        street: Yup.string().test('addressValidation', t('LBLAddressVerificationError'), async function (value) {
                  const response = await handleAddressValidation({ ...this.parent, street: value });
                  if(response === true){
                  return true; 
                  }else 
                  return false;
                })
              .required("LBLCityReq")
              .label(t('lblStName')),
        city: Yup.string().test('addressValidation', t('LBLAddressVerificationError'), async function (value) {
                  const response = await handleAddressValidation({ ...this.parent, city: value });
                  if(response === true){
                  return true; 
                  }else 
                  return false;
              })
              .required(t('LBLLocReq')),
        postalCode: Yup.number().test('addressValidation', t('LBLAddressVerificationError'), async function (value) {
                      const response = await handleAddressValidation({ ...this.parent, postalCode: value });
                      if(response === true){
                      return true; 
                      }else 
                      return false;
                    })
                  .required(t('LBLZipcodeReq'))
                  .label(t('PostalCode')),
        dateOfBirth: Yup.date().label(t('LBLBirthDate')),
        phoneNumber: Yup.string().label(t('LBLHandy'))
      }),
    []
  );
 
  const handleAddressValidation = async (values) => {
    try {
      const { street, postalCode, city } = values;
      const requestBody = { street, postalCode, city };
      
      const response = await api.addressVerification(token, requestBody);
      if(response)
      return true; 
    } catch (error) {
      return error.message || 'An error occurred during address validation';
    }
  };

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = async (values, actions) => {

    const payload = {
      salutation: values.salutation,
      fullName: values.fullName,
      emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      dateOfBirth: format(new Date(values.dateOfBirth), "yyyy-MM-dd"),
      address: {
        city: values.city,
        street: values.street,
        houseNr: values.houseNr,
        postalCode: values.postalCode,
      }
    };
    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  
  };
  
   
  return (
    <>
      <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
      validateOnChange={true}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        isValid,
        handleChange
      }) => (
        <Form noValidate >
          <>
          {
                setCanNext(
                    touched && isValid
                )
                }
            
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="salutation"
                name="salutation"
                label={t('LBLUserAnrede')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                // style={{ width: "400px" }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="fullname"
                name="fullName"
                label={t('fullName')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                // style={{ width: "400px" }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="street"
                name="street"
                label={t('lblStName')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                onChange={handleChange}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="houseNr"
                name="houseNr"
                label={t('LBLHouseNr')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="postalCode"
                name="postalCode"
                label={t('PostalCode')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                onChange={handleChange}
              />
              <Field
                required
                fullWidth
                margin="none"
                component={TextField}
                id="city"
                name="city"
                label={t('city')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                onChange={handleChange}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="phoneNumber"
                name="phoneNumber"
                label={t('LBLHandy')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                required
                sx={{ width: '500px' }}
              />
              
                
            <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                 
                  <DatePicker
                    label={t('LBLBirthDate')}
                    //minDate={dayjs(minStartDate)}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    value={values.dateOfBirth}
                    id={"dateOfBirth"}
                    onChange={(newValue) => {
                      setFieldValue(
                        "dateOfBirth",
                        newValue ? newValue.toDate() : ""
                      );
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        required
                        name="dateOfBirth"
                        margin="none"
                        autoComplete="off"
                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                        error={errors.dateOfBirth && touched.dateOfBirth}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "TT.MM.JJJJ",
                        }}
                        sx={{ width: '500px' }}
                      />
                    )}
                   
                  />
                  
                </LocalizationProvider>
                </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="emailAddress"
                name="emailAddress"
                label="E-Mail"
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                required
              />
            </Stack>
           

            <Button sx={{ display: "none" }} id="sub" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
    </>
  )
}
EditUserDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditUserDetails