import { CardContent, Typography } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next';
import { useCallback } from "react";
import { formatDecimal } from "../../../services/utils/format-numbers";

function GenerationPlantDetails({contract}) {
    const { t, i18n } = useTranslation();

    const plantDetails = {
      NameOfPowerPlant: contract?.plantDetails?.plantName,
      PowerPlant: contract?.plantDetails?.plantType ,
      PlantAdresse: contract?.plantDetails?.plantAddress?.street
      ? `${contract?.plantDetails?.plantAddress?.postalCode} ${contract?.plantDetails?.plantAddress?.city} `
      : null,
      InstalledOutput: formatDecimal(contract?.plantDetails?.installedPowerKw),
      CommissioningDate: contract?.plantDetails?.commisioningDate ,
      MarketLocation: contract?.plantDetails?.marketLocation,
      MeterNumber: contract?.plantDetails?.meterNumber,
      PlantKey: contract?.plantDetails?.plantKey,
      Operator: contract?.plantDetails?.netOperator,
    };
  
    const renderKey = useCallback((key) => {
      switch (key) {
        case "NameOfPowerPlant":
          return t('LBLPlantName');
        case "PowerPlant":
          return contract?.contractType === "PRODUCER" ? t('LBLPlantType') : t('LBLPreference');
        case "PlantAdresse":
          return t('LBLPlantAdress');
        case "InstalledOutput":
            return t('LBLInstalledOutput');
        case "CommissioningDate":
            return t('LBLCommissioningDate');
        case "MarketLocation":
            return t('LBLMarketLocation');
        case "MeterNumber":
            return t('LBLMeterNumber');
        case "PlantKey":
            return t('LBLPlantKey');
        case "Operator":
            return t('LBLOperator');            
        default:
          return key;
      }
    }, [contract]);
    return (
      <>
      <View>
          <CardContent>
          <Typography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLGenerationPlantDetails')}
          </Typography>
          <Spacer mb={4} />
          <Typography variant="body2" component="div" gutterBottom>
            {plantDetails && (
              <>
              {Object.entries(plantDetails).map(([key, value])=>{
                return (
                  <>
                  <Item key={key}>
                    <Typography fontWeight= "fontWeightMedium">
                      {renderKey(key)}
                    </Typography>
                    {key == "Adresse" ? (<Typography fontWeight="fontWeightRegular" align="right">
                      {contract?.plantDetails?.plantAddress?.postalCode} {contract?.plantDetails?.plantAddress?.city}
                    </Typography>) : (<Typography fontWeight="fontWeightRegular" align="right">
                          {["string", "number"].includes(typeof value)
                            ? value
                            : value?.join(", ") || null}
                        </Typography>)}
                  </Item>
                  </>
                )
              })}
              </>
            )}
          </Typography>
          </CardContent>
      </View>
      </>
      
    )
}

export default GenerationPlantDetails