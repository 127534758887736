import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
const resources = {
  en: {
    translation: translationEN,
  },

  de: {
    translation: translationDE,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:"de", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
