import { Button, Box, Typography, Select, MenuItem, Grid, Card as MuiCard, TextField as MuiTextField} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { spacing } from "@mui/system";
import shadows from "@mui/material/styles/shadows";
import styled from "styled-components/macro";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import format from "date-fns/format";
import { formatDecimal } from "../../services/utils/format-numbers";
import { useAuth } from "../../services/contexts";
import api from "../../Middleware/api/api";
import Feedback from "../../components/Feedback";
import ErrorFeedback from "../../components/DataTable/Feedback";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EnergySummaryBox = styled.div`
  flex: 1 0 20%; 
  min-width: 150px; 
  max-width: 350px; 
  margin: 10px;
  padding: 10px;
  background-color: white;
  border: 4px solid #D0D0D0;
  border-radius: 10px;
`;
const Column = styled.div`
  flex: 1;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const Card = styled(MuiCard)(spacing);
function calculatedates(days) {
  let date = new Date();
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  );
}
function EnergySummaryKpi() {
    const { t, i18n } = useTranslation();
    const { token } = useAuth();
    const [timePeriod, setTimePeriod] = useState([ "YEAR"]);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(calculatedates(1));
    const [endDate, setEndDate] = useState(calculatedates(0));
    const now = new Date();
    const [endTime, setEndTime] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [energyData, setEnergyData] = useState(null);
    const [error, setError] = useState(false);
    const [showError, setShowError] = useState(false)
    const lebelStyle = { marginBottom: 0, textAlign: "right", marginTop: "40px", fontSize: "12px" };

    const handleChangeTimePeriod = (event) => {
        setTimePeriod(event.target.value);
      };
      const closeFeedback = () => {
        setError(false)
        setShowError(false)
      }

      useEffect(() => fetchEnergySummaryData(startDate, endDate, format(startTime, 'HH:mm'), format(endTime, 'HH:mm')), 
      [timePeriod]);

      const fetchEnergySummaryData = (startDate, endDate, startTime, endTime) =>{

        try{
          const params = new URLSearchParams({
            dateUnit: timePeriod,
            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime
          })

          api.getEnergySummaryKpi(token, params).then(
            (response)=>{
              if(response.data.data !== null){
              setEnergyData(response.data)
              console.log("Data is " + JSON.stringify(response.data.data))
              }else if(response.data.data == null) {
              console.log("else part")
              setError(true)
              setEnergyData(null)}
            } 
            )
        }catch (exception){
            setEnergyData(null);
            setShowError(true)
        }
      }

      const initialValues = {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime
      };
      const validationSchema = useMemo(
        () =>
          Yup.object({
            startDate: Yup.date().required(i18n.t('LBLStartDateReq')),
            endDate: Yup.date()
              .min(Yup.ref('startDate'), i18n.t('LBLValidDateError'))
              .required(i18n.t('LBLEndDateReq')),
          }),
        []
      );

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setStartTime(values.startTime);
    setEndTime(values.endTime);
    fetchEnergySummaryData(values.startDate, values.endDate, format(values.startTime, 'HH:mm'), format(values.endTime, 'HH:mm'))
   
    actions.setSubmitting(false);
    actions.setTouched({}, true);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, true, true);
  };
  return (
    <>
    <div>
    <Typography variant="h4" gutterBottom>
          {t('LBLEnergySummary')}
          </Typography>
          <Typography  gutterBottom>
          {t('LBLTimeSelection')}
          </Typography>
                <MuiTextField
                  select
                  id="timeSelection"
                  name="timeSelection"
                  defaultValue={t('LBLYear')}
                  value={timePeriod}  
                  onChange={handleChangeTimePeriod}
                  label={t('LBLTimeSelection')}
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  sx={{ width: '265px' ,display: 'flex'}}
                >
                          <MenuItem value="YEAR">{t('LBLYear')}</MenuItem>
                          <MenuItem value="YESTERDAY">{t('LBLYesterday')}</MenuItem>
                          <MenuItem value="TIMEPERIOD">{t('LBLTimePeriod')}</MenuItem>
                 </MuiTextField>
                 </div> 
        {timePeriod === 'TIMEPERIOD' && (
            <>
            <Container>
            <Box display="flex" alignItems="center" marginTop={2}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleSubmit}
              validateOnChange={true}
             
           >
             {({
               errors,
               touched,
               values,
               setFieldValue,
             }) => (
               <Form noValidate >
                 <>  
                 <Grid container spacing={2} alignItems="center">
                 <Grid item>
                <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        <div style={{ width: '300px', marginBottom: '10px' }}>
                          <DatePicker
                            label={t('LBLStartDate')}
                            PaperProps={{ sx: { boxShadow: shadows[3] } }}
                            id={"startDate"}
                            value={values.startDate}
                            maxDate={dayjs()}
                            onChange={(newValue) => {
                              setFieldValue(
                                'startDate',
                                newValue
                                  ? format(newValue.toDate(), 'yyyy-MM-dd')
                                  : ''
                              );
                            }}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                required
                                name="startDate"
                                margin="none"
                                autoComplete="off"
                                helperText={
                                  touched.startDate && t(errors.startDate)
                                }
                                error={t(errors.startDate) && touched.startDate}
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                </div>
                </Grid> 
                <Grid item>
                <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        
                        <div style={{ width: '300px', marginBottom: '10px' }}>
                          <TimePicker
                            label={t('LBLStartTime')}
                            PaperProps={{ sx: { boxShadow: shadows[3] } }}
                            value={values.startTime}
                            id={"startTime"}
                            minutesStep={15}
                            onChange={(newValue) => {
                              setFieldValue(
                                'startTime',
                                newValue.toDate()
                              );
                            }}
                            renderInput={(params) => <MuiTextField 
                              {...params}
                              name={"startTime"}
                              margin="none"
                              autoComplete="off"
                              helperText={touched.startTime && t(errors.startTime)}
                              error={t(errors.startTime) && touched.startTime} 
                            />}
                          />
                        </div>
                      </LocalizationProvider>
                </div>
                </Grid> 
                <Grid item>
                <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        <div style={{ width: '300px', marginBottom: '10px' }}>
                          <DatePicker
                            label={t('LBLEndDate')}
                            PaperProps={{ sx: { boxShadow: shadows[3] } }}
                            id={"endDate"}
                            value={values.endDate}
                            maxDate={dayjs()}
                            onChange={(newValue) => {
                              setFieldValue(
                                'endDate',
                                newValue
                                  ? format(newValue.toDate(), 'yyyy-MM-dd')
                                  : ''
                              );
                            }}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                required
                                name="endDate"
                                margin="none"
                                autoComplete="off"
                                helperText={
                                  touched.endDate && t(errors.endDate)
                                }
                                error={t(errors.endDate) && touched.endDate}
                               
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                </div>
                </Grid> 
                <Grid item>
                <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        <div style={{ width: '300px', marginBottom: '10px' }}>
                        <TimePicker
                            label={t('LBLEndTime')}
                            PaperProps={{ sx: { boxShadow: shadows[3] } }}
                            value={values.endTime}
                            id={"endTime"}
                            minutesStep={15}
                            onChange={(newValue) => {
                              setFieldValue(
                                'endTime',
                                newValue.toDate()
                              );
                            }}
                            renderInput={(params) => <MuiTextField 
                              {...params}
                              name={"endTime"}
                              margin="none"
                              autoComplete="off"
                              helperText={touched.endTime && t(errors.endTime)}
                              error={t(errors.endTime) && touched.endTime} 
                            />}
                          />
                        </div>
                      </LocalizationProvider>
                </div>
                </Grid> 
                <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t('LBLSubmit')}
                </Button>
                </Grid>
                
                </Grid>
                </>
                </Form>
                 )}
             </Formik>
             </Box>
            </Container>
             </>
                 )
            }

             {/* here starts the energy summary boxes and graph portion */}
             <Container>  
                <Column></Column>
                <Column>
                <Card>
                <Typography variant="h4" gutterBottom>
                  {t('LBLEnergyInputOutput')}
              </Typography>
                <Row>
              <EnergySummaryBox >
                <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
                {t('LBLTotalLocalProduction')}
                </Typography>
                <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalEnergyProduction > 0 ? (formatDecimal(energyData?.data?.totalEnergyProduction)+ " kWh" ): "0"} </p>
             </EnergySummaryBox>
            <EnergySummaryBox >
                <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
                {t('LBLTotalDemand')}
                </Typography>
                <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalDemand > 0 ? (formatDecimal(energyData?.data?.totalDemand)+ " kWh" ) : "0"} </p>
            </EnergySummaryBox>
            <EnergySummaryBox>
              <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
              {t('LBLTotalGridEnergy')}
              </Typography>
              <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalGridEnergy > 0 ? (formatDecimal(energyData?.data?.totalGridEnergy)+ " kWh" ) : "0"} </p>
            </EnergySummaryBox>
            {/* Local Energy Sources boxes starts below */}
            </Row>
            <Typography variant="h4" gutterBottom>
              {t('LBLLocalEnergyShare')}
             </Typography>
                <Row>
           <EnergySummaryBox>
            <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
            {t('LBLSolarEnergy')}
            </Typography>
            <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalProductionByEnergySources?.SOLAR_POWER > 0 ? (formatDecimal(energyData?.data?.totalProductionByEnergySources?.SOLAR_POWER) + " kWh") : "0,0 kWh" } </p>
          </EnergySummaryBox>
            <EnergySummaryBox>
              <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
              {t('LBLWindEnergykwh')}
              </Typography>
              <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalProductionByEnergySources?.WIND_ENERGY > 0 ? (formatDecimal(energyData?.data?.totalProductionByEnergySources?.WIND_ENERGY) + " kWh") : "0,0 kWh" } </p>
            </EnergySummaryBox>
            </Row>
            <Row>
             <EnergySummaryBox>
                <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
                {t('LBLHydroEnergy')}
                </Typography>
                <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalProductionByEnergySources?.HYDRO_POWER > 0 ? (formatDecimal(energyData?.data?.totalProductionByEnergySources?.HYDRO_POWER) + " kWh") : "0,0 kWh" } </p>
             </EnergySummaryBox>
              <EnergySummaryBox>
                <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
                {t('LBLBiomassEnergy')}
                </Typography>
                <p style={lebelStyle}>{energyData !== null && energyData?.data?.totalProductionByEnergySources?.BIO_MASS > 0 ? (formatDecimal(energyData?.data?.totalProductionByEnergySources?.BIO_MASS) + " kWh") : "0,0 kWh" } </p>
              </EnergySummaryBox>
            </Row>
            </Card>
          </Column>
        </Container>

                    <Feedback
                      open={error}
                      title={t('LBLDataInfo')}
                      message={
                        <>
                          <Typography align="center" >
                            {t('LBLDataMsg')}
                          </Typography>
                        </>
                      }
                      handleClose={closeFeedback}
                  />

                    <ErrorFeedback
                      open={showError}
                      title={t('LBLErrorTitle')}
                      severity="warning"
                      message={
                        <Typography
                          variant="subtitle1"
                          sx={{ width: 400, mx: 10 }}
                        >
                          {t('LBLErrorMsgForException')}
                        </Typography>
                      }
                      handleClose={closeFeedback}
                    />
    
    </>
   
  )
}

export default EnergySummaryKpi