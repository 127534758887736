import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Chip as MuiChip } from "@mui/material";
import { green, grey, orange } from "@mui/material/colors";

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;

export const View = styled.div`
  flex: 1;
  background: white;
`;

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 16px;
`;

export const Spacer = styled.div(spacing);

export const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => props.inactive && grey[300]};
  color: ${(props) =>
    (props.active || props.pending) && props.theme.palette.common.white};
`;
