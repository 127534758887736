import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

import { WarningRounded } from "@mui/icons-material";

const WarnIcon = styled(WarningRounded)`
  width: 312px;
  height: 92px;
`;
function RouteNotFound() {

  return (
    <>
      <Dialog
        open={true}
        PaperProps={{ sx: { py: 5, px: 8 } }}
      >
        <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "x-large",
              pb: 8,
            }}
          >
          Seite nicht gefunden
        </DialogTitle>
        <WarnIcon
          color={"warning"}
          sx={{ display: "flex", mx: "auto", fontSize: "xxx-large" }}
        />
      
        <DialogContent sx={{ textAlign: "center", py: 5 }}>
          Bitte überprüfen Sie den Link oder gehen Sie zur Login-Seite
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button variant="contained" sx={{ px: 6 }} component={NavLink} to={"/login"}>
            zum Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RouteNotFound;
