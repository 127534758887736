import { useMemo, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button } from '@mui/material';
import Stack from "@mui/material/Stack";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";


function PaymentDetails( {
  payload,
  setPayloadOne,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext}) {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (submit > 0) document.getElementById("subOne").click();
    setSubmit(0);
  }, [submit]);

  const initialValues = {
    bankName: payload?.paymentDetails?.bankName || "",
    bic: payload?.paymentDetails?.bic || "",
    iban: payload?.paymentDetails?.bankName || "",
    accountHolder: payload?.paymentDetails?.accountHolder || "",
    registrationNumber: payload?.paymentDetails?.registrationNumber || "",
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        bankName: Yup.string().required(t('LBLBankRequired'))
          .label(t('LBLBankName')),
          bic: Yup.string()
          .required(t('LBLbicreq'))
          .label(t('LBLBic')),
          iban: Yup.string()
          .required(t('LBLibanReq'))
          .label("LBLIban"),
          accountHolder: Yup.string().required(t('LBLAccountReq'))
          .label(t('LBLAccountHolder')),
          registrationNumber: Yup.string().required(t('LBLRegistrationReq')).label(t('LBLRegistrationNumber')),
      }),
    []
  );
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      bankName: values.bankName,
      bic: values.bic,
      iban: values.iban,
      accountHolder: values.accountHolder,
      registrationNumber: values.registrationNumber,
    };

    setPayloadOne(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);

  };
   
  return (
    <>
       <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({
        touched,
        isValid,
      }) => (
        <Form noValidate >
          <>
            {
            setCanNext(touched && isValid)
            }
              
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="bankName"
                name="bankName"
                label={t('LBLBankName')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="bic"
                name="bic"
                label={t('LBLBic')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="iban"
                name="iban"
                label={t('LBLIban')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="accountHolder"
                name="accountHolder"
                label={t('LBLAccountHolder')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="registrationNumber"
                name="registrationNumber"
                label={t('LBLRegistrationNumber')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            
            </Stack>
            

            <Button sx={{ display: "none" }} id="subOne" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
    </>
  )
}
PaymentDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadOne: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PaymentDetails