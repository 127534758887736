import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { useAuth } from "../../services/contexts/AuthContext";
import api from "../../Middleware/api/api";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Card as MuiCard,

} from "@mui/material";
import { spacing } from "@mui/system";
import LogoLoader from "../../components/LogoLoader";
import EnergySummaryKpi from "./EnergySummaryKpi";
import { formatDecimal } from "../../services/utils/format-numbers";

const Card = styled(MuiCard)(spacing);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Box = styled.div`
  flex: 1 0 20%; 
  min-width: 200px; 
  max-width: 450px; 
  margin: 10px;
  padding: 10px;
  background-color: white;
  border: 4px solid #D0D0D0;
  border-radius: 10px;
`;
function BarChart({ theme }) {
  const { t } = useTranslation();

  const [userData, setUserData] = useState();

  const lebelStyle = { marginBottom: 0, textAlign: "right", marginTop: "40px", fontSize: "12px" };

  const { token } = useAuth();

  const getDashboardData = async () => {
    api.dashboardData(token).then(
      (response) => {
        setUserData(response?.data?.data)
      }
    );
  };
 
  useEffect(() => getDashboardData(), []);

  return (
    <>
    <Card>
      { !userData ?  (  <LogoLoader loading={true}></LogoLoader>) : 
        (
        <Container>  
          <Box
          >
            <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
            {t('LBLTotalUsers')}
            </Typography>
            <p style={lebelStyle}>{userData !== null && userData?.totalUsers > 0 ? userData?.totalUsers  : "0"} </p>
          </Box>
       
          <Box
          >
            <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
            {t('LBLTotalConsumers')}
            </Typography>
            <p style={lebelStyle}>{userData !== null && userData?.totalConsumers > 0 ? userData?.totalConsumers  : "0"} </p>
            </Box>
        
          <Box
          >
            <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
            {t('LBLTotalProducers')}
            </Typography>
            <p style={lebelStyle}>{userData !== null && userData?.totalProducers > 0 ? userData?.totalProducers  : "0"} </p>
            </Box>
        
          <Box
          >
            <Typography variant="h3" style={{ width: "100%", }} fontSize={"small"} display="inline">
            {t('LBLTotalEnergyCapacity')}
            </Typography>

            <p style={lebelStyle}>{userData !== null && userData?.totalEnergyCapacity > 0 ? (formatDecimal(userData?.totalEnergyCapacity) + " kW") : "0,0 kW" } </p>
            </Box>
        </Container>
      )}
      </Card>
      <div style={{  height: "10px"}} /> 
      <EnergySummaryKpi/>
     </>
    
  );
}
export default withTheme(BarChart);
