import { useEffect, useState } from "react";
import DetailsWrapper from "../../../layouts/DetailsWrapper"
import {
  TextField, MenuItem,Grid, Typography , Button
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import shadows from "@mui/material/styles/shadows";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import UserInformation from "./UserInformation";
import BankInformation from "./BankInformation";
import OtherData from "./OtherData";
import GenerationPlantDetails from "./GenerationPlantDetails";
import { makeStyles } from '@mui/styles';
import EditUserDetails from "./EditUserDetails";
import StepperPopup from "../../../components/StepperPopup";
import ConfirmUserDetails from "./ConfirmUserDetails";
import PaymentDetails from "./PaymentDetails";
import ProductionPlantDetails from "./ProductionPlantDetails";
import OtherDataDetails from "./OtherDataDetails";
import Feedback from "../../../components/Feedback";
import api from "../../../Middleware/api/api";
import { useAuth } from "../../../services/contexts";
import BillingAddressDetails from "./BillingAddressDetails";
import LogoLoader from "../../../components/LogoLoader";


const useStyles = makeStyles({
  borderedContainer: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '10px',
  },
});

export default function NutzerDetail() {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedContractId, setSelectedContractId] = useState(null);
    const [refreshTheData, setRefreshTheData] = useState(false);
    const [contract, setContract] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editUserDetails, setEditUserDetails] = useState(false);
    const [step, setStep] = useState(0);
    const [canNext, setCanNext] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [confirmed, setConfirmed] = useState(0);
    const [canNextOne, setCanNextOne] = useState(false);
    const [canNextTwo, setCanNextTwo] = useState(false);
    const [canNextThree, setCanNextThree] = useState(false);
    const [canNextBilling, setCanNextBilling] = useState(false);
    const [submitOne, setSubmitOne] = useState(0);
    const [submitTwo, setSubmitTwo] = useState(0);
    const [submitThree, setSubmitThree] = useState(0);
    const [submitBilling, setSubmitBilling] = useState(0);
    const [contractData, setContractData] = useState(null);
    const handleNext = () => setStep(step + 1);
    const handlePrev = () => setStep(step - 1);
    const [payload, setPayload] = useState();
    const [payloadOne, setPayloadOne] = useState();
    const [payloadTwo, setPayloadTwo] = useState();
    const [payloadThree, setPayloadThree] = useState();
    const [payloadBilling, setPayloadBilling] = useState();
    const [completed, setCompleted] = useState(false);
    const openFeedback = () => setCompleted(true);
    const [openPopup, setOpenPopup] = useState(false);
    const [error, setError] = useState(false);
    const [successFeedback, setSuccessFeedback] = useState(false);
    ////////////
    const location = useLocation();
    const { user } = location.state || {};
    
    const { token } = useAuth();
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      if(refreshTheData) 
      fetchContractDetails();
    }, [refreshTheData]);

    const fetchContractDetails = async () => {
      try {
        const response = await api.fetchContractData(
          token,
          contractData?.contractKey,
        );
        if(response.data)
        setContract(response.data)
      } catch (error) {
          setError(true);
        }
    };
    
    const handleSetPayload = (input) => {
      setPayload(input)
     
    }
    const handleSetPayloadOne = (input) => {
      setPayloadOne(input)
    }
    const handleSetPayloadTwo = (input) => {
      setPayloadTwo(input)
    }

    const handleSetPayloadThree = (input) => {
      setPayloadThree(input)
    }

    const handleSetPayloadBilling = (input) => {
        setPayloadBilling(input)
    }
    
    const handleContractChange = (event) =>{
      setSelectedContractId(event.target.value);
    }

    const handleClick = (value) => {
      setContractData(value);
      setEditUserDetails(true);
      setPayloadTwo(value)
    };
    const setSubmittingConfirm = () => {
      setEditUserDetails(false)
  
    }
    const handleNextevent = (input) => {
      handleNext()
    }
    const onClose = (_event, reason) => {
      if (reason !== "backdropClick") {
        handleClose();
        setStep(0);
        setPayload(null);
      }
    };
     /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== "backdropClick") {
      setPayloadOne(null)

      setStep(1);

    }
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseTwo = (_event, reason) => {
    if (reason !== "backdropClick") {
      setPayloadTwo(null)

      setStep(2);

    }
  };
   /** @type {import("@mui/material").DialogProps['onClose']} */
   const onCloseThree = (_event, reason) => {
    if (reason !== "backdropClick") {
      setPayloadThree(null)
      setStep(3);

    }
  };
    const closeFeedback = () => {
      setCompleted(false);
      setPayload(null);
      setOpenPopup(false);
      setSuccessFeedback(false);
      setAnchorEl(null);
    }

    const classes = useStyles();
  return (
    <>   
     <DetailsWrapper
      title={t("LBLUserMgmt")}
      loading={loading}
      helmet="invoices"
      backButtonConfig={{label: t("lblBacktoview"), url: "/nutzer"}}
      contract={""}
    >
      <div style={{ width: '20%', height: "70px", textAlign: "left", float: "left" }}>
        <div style={{ backgroundColor: "transparent", marginLeft: '10px', marginTop: '-15px' }} >
                        <TextField
                          select
                          id="contractDetails"
                          label={t("lblTerms")}
                          onChange={handleContractChange}
                          value={selectedContractId}
                          name="contractDetailSelection"
                          style={{ width: '200px' }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  width: '200px', 
                                },
                              },
                            },
                          }}
                        >
                         { 
                          user.contractDataDto && user.contractDataDto.length >0 ? (
                            user.contractDataDto.map((contract, index)=>(
                              <MenuItem size="large" key={index} value={contract.contractKey}>
                              {contract?.contractType === "CONSUMER" ? ` ${t("LBLConsumerContract")} ${index + 1}` : `${t('LBLProducerContract')} ${index + 1}`}
                            </MenuItem>
                            ))
                          ):(
                            <MenuItem value="">
                                <Typography fontWeight="bold" variant="h4" gutterBottom>
                                  {t('LBLSelectContract')}
                                </Typography>
                            </MenuItem>
                              )}
                        </TextField>
                      </div>
                      </div>
                      
                      {selectedContractId && selectedContractId != null? (

                    !user.contractDataDto ? (<LogoLoader loading={true}/>) :(
                      user.contractDataDto.map((contract) => {
                      if (contract.contractKey === selectedContractId) {
                      return (
                        <>
                        <Button
                        id="demo-positioned-button"
                        onClick={()=>handleClick(contract)}
                        variant="contained"
                        sx={{
                          position: "absolute",
                          boxShadow: shadows[3],
                          top: ({ spacing }) => spacing(10),
                          right: ({ spacing }) => spacing(10),
                          zIndex: ({ zIndex }) => zIndex.appBar,
                          display: contract?.contractStatus === "EXPIRED" ? "none" : "flex",
                          justifyContent: "center",
                        }}
                      >
                        {t('LBLEditUserDetail')}
                      </Button>
                      <Grid container direction= 'row' spacing={2}>
                      <Grid item xs={6} >
                        <div className={classes.borderedContainer}>
                        <UserInformation contract={contract}/>
                        </div>
                      </Grid>
                      <Grid item xs={6} >
                        <div className={classes.borderedContainer}>
                        <GenerationPlantDetails contract={contract}/>
                        </div>
                      </Grid>
                      <Grid item xs={6} >
                        <div className={classes.borderedContainer}>
                        <OtherData contract={contract}/>
                        </div>
                      </Grid>
                      <Grid item xs={6} >
                        <div className={classes.borderedContainer}>
                        <BankInformation contract={contract}/>
                        </div>
                      </Grid>
                    </Grid> </>);
                      } else
                      return null;
                      }))
                    ) : (
                      <div style={{ backgroundColor: "transparent", marginLeft: '80px', marginTop: '65px' }}>
                        <Typography fontWeight="bold" variant="h4" gutterBottom>
                            {t('LBLSelectContract')}
                        </Typography>
                      </div>
                    )
                   }
                                                            
    </DetailsWrapper>

    {/* user details edit workflow starts here: */}
                  <StepperPopup
                  widthInput="1000"
                  title={t('LBLEditUser')}
                  isOpen={editUserDetails}
                  setIsOpen={setEditUserDetails}
                  steps={[
                    {
                    label: t('LBLUserInfo'),
                    component: <EditUserDetails
                       setPayload={handleSetPayload}
                       handleClose={onClose}
                       confirmPayload={handleNext}
                       payload={contractData}
                       setSubmit={setSubmit}
                       setCanNext={setCanNext}
                       submit={submit}
                    />,
                    nextAction: {
                      label: t('LBLFurther'), icon: <ArrowForward />,
                      action: () => { setSubmit(prevState => prevState + 1); },
                      canNext: canNext
                    }
                  },
                  {
                    label: t('LBLBillingAddressInfo'),
                    component: <BillingAddressDetails
                       setPayload={handleSetPayloadBilling}
                       handleClose={onClose}
                       confirmPayload={handleNext}
                       payload={contractData}
                       setSubmit={setSubmitBilling}
                       setCanNext={setCanNextBilling}
                       submit={submitBilling}
                    />,
                    nextAction: {
                      label: t('LBLFurther'), icon: <ArrowForward />,
                      action: () => { setSubmitBilling(prevState => prevState + 1); },
                      canNext: canNextBilling
                    }
                  },
                   {
                    label: t('LBLAccountData'),
                    component: <PaymentDetails
                      setPayloadOne={handleSetPayloadOne}
                      handleClose={onCloseOne}
                      confirmPayload={handleNextevent}
                      payload={contractData}
                      setSubmit={setSubmitOne}
                      setCanNext={setCanNextOne}
                      submit={submitOne}
                    />,
                    nextAction: {
                      label: t('LBLFurther'),
                      icon: <ArrowForward />,
                      action: () => {
                        setSubmitOne(prevState => prevState + 1);
                      },
                      canNext: canNextOne
                    }
                  },
                  {
                    label: t('LBLGenerationPlantDetails'), 
                    component: <ProductionPlantDetails
                      setPayload={handleSetPayloadTwo}
                      handleClose={onCloseTwo}
                      confirmPayload={handleNext}
                      payload={contractData}
                      setSubmit={setSubmitTwo}
                      setCanNext={setCanNextTwo}
                      submit={submitTwo}
                    />,
                    nextAction: {
                      label: t('LBLFurther'), icon: <ArrowForward />,
                      action: () => { setSubmitTwo(prevState => prevState + 1); },
                      canNext: canNextTwo
                    }
                  },
                  {
                    label: t('LBLOtherData'), 
                    component: <OtherDataDetails
                      setPayloadThree={handleSetPayloadThree}
                      handleClose={onCloseThree}
                      confirmPayload={handleNext}
                      payload={contractData}
                      setSubmit={setSubmitThree}
                      setCanNext={setCanNextThree}
                      submit={submitThree}
                    /> ,
                    nextAction: {
                      label: t('LBLFurther'), icon: <ArrowForward />,
                      action: () => { setSubmitThree(prevState => prevState + 1); },
                      canNext: canNextThree
                    }
                  },
                  {
                    label: t('confirmInfo'),
                    component: <ConfirmUserDetails
                       payload={
                         {
                            salutation: payload?.salutation,
                            fullName: payload?.fullName,
                            address: payload?.address,
                            billingAddress: { 
                                city: payloadBilling?.billingAddress?.city,
                                street: payloadBilling?.billingAddress?.street,
                                houseNr: payloadBilling?.billingAddress?.houseNr,
                                postalCode: payloadBilling?.billingAddress?.postalCode,
                                },
                            billingAddressOwner: payloadBilling?.billingAddressOwner,
                            phoneNumber: payload?.phoneNumber,
                            dateOfBirth: payload?.dateOfBirth,
                            emailAddress: payload?.emailAddress,
                            contractStatus: payloadThree?.contractStatus,
                            contractType: payloadThree?.contractType,
                            preference: payload?.preference,
                            plantDetails: {
                                plantType: payloadTwo?.plantType,
                                plantName: payloadTwo?.plantName,
                                costumerFullName: contractData?.plantDetails?.costumerFullName,
                                costumerNumber: contractData?.plantDetails?.customerNumber || "",
                                plantAddress: {
                                    street: payloadTwo?.plantAddress?.street ,
                                    houseNr: payloadTwo?.plantAddress?.houseNr ,
                                    city: payloadTwo?.plantAddress?.city ,
                                    postalCode: payloadTwo?.plantAddress?.zipCode
                                },
                                installedPowerKw: payloadTwo?.installedPowerKw,
                                commisioningDate: payloadTwo?.commisioningDate,
                                marketLocation: payloadTwo?.marketLocation,
                                meterNumber: payloadTwo?.meterNumber,
                                netOperator: payloadTwo?.operator,
                                plantKey: payloadTwo?.plantKey
                            },
                            startOfDelivery: payloadThree?.startOfDelivery,
                            salesTaxLiability: payloadThree?.salesTaxLiability,
                            paymentDetails: {
                              bankName: payloadOne?.bankName,
                              bic: payloadOne?.bic,
                              iban: payloadOne?.iban,
                              accountHolder: payloadOne?.accountHolder,
                              registrationNumber: payloadOne?.registrationNumber
                          },
                            contractKey: contractData?.contractKey,
                            userId: contractData?.userId
                         }
                       }
                       handleClose={onClose}
                       openFeedback={openFeedback}
                       backToForm={handlePrev}
                       setError={setError}
                       setSubmittingConfirm={setSubmittingConfirm}
                       submit={confirmed}
                       setSubmit={setConfirmed}
                       contract={contract}
                       setRefreshTheData={setRefreshTheData}
                    />,
                    nextAction: {
                      label: t('LBLSave'),
                      action: () => {
                        setConfirmed(prevState => prevState + 1);
                        setEditUserDetails(false);
                        setLoading(false);
                      }
                    }
                  }
                ]}
                  ></StepperPopup>
                   <Feedback
                      open={completed}
                      title={t('LBLContractUpdated')}
                      message={
                        <>
                          <Typography align="center" >
                            {t('LBLUserContractUpdated')}
                          </Typography>
                        </>
                      }
                      handleClose={closeFeedback}
                  />
    </>
   

  );
}
