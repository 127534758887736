import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

export const tableTheme = createMuiTheme({
  components: {
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          "& + th > *": {
            justifyContent: "flex-start !important",
          },
          backgroundColor: "#fff",
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          "&[data-colindex]:not(:first-of-type) > *": {
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
  },
});

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      components,
      typography,
      shadows,
      palette: themeConfig.palette,
      status: {
        aktiv: "#4caf50",
        verknüpft: "#ffa520",
        inaktiv: "#95908d",
        standby: "#0c5ead",
        störung: "#ed4848",
        abgelaufen: "#EB570F"
        
        
      },
      payment: {
        bezahlt: "#4caf50",
        offen: "#95908d",
      },
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

export default createTheme;
