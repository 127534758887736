import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Logo from "../../vendor/OLI_Community_Logo.png";
import SignInComponent from "../../components/auth/SignIn";

function SignIn() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet title="Login" />
      <img src={Logo} alt="logo" width={250} height={63} />
      <Container maxWidth="xs">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ mt: 8, mb: 6 }}
        >
          {t('LBLLogin')}
        </Typography>
        <SignInComponent />
      </Container>
    </>
  );
}

export default SignIn;
