// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// Guards
import AuthGuard from "./components/guards/AuthGuard";
// Views
import Users from "./pages/Users";
import NutzerDetail from "./pages/Users/UserDetail";
import Cockpit from "./pages/Dashbord/Cockpit";
// Auth components
import SignIn from "./pages/auth/SignIn";
import ForgetPassword from "./pages/auth/ForgetPassword";
import RouteNotFound from "./pages/RouteNotFound";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Cockpit />
          </AuthGuard>
        ),
      },
    ],
  },
 {
    path: "cockpit",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Cockpit />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "nutzer",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: ":user",
        element: (
          <AuthGuard>
            <NutzerDetail />
          </AuthGuard>
        ),
      },
      
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "einloggen",
        element: <SignIn />,
      },
      {
        path: "forgot-password",
        element: <ForgetPassword />,
      },
      {
        path: "passwort-vergessen",
        element: <ForgetPassword />,
      },
    ],
  },
  /*{
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },*/
  {
    path: "*",
    element: (
      <AuthLayout>
        <RouteNotFound />
      </AuthLayout>
    ),
    children: [],
  },

];

export default routes;
