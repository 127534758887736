import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

/**
 * @typedef {Object} UserProps
 * @property {string} email
 * @property {boolean} email_verified
 * @property {string} name
 * @property {string} preferred_username
 */

/**
 * @type {React.Context<{user?: import("keycloak-js").KeycloakTokenParsed & UserProps}>}
 */
const Context = createContext();

const UserProvider = ({ children }) => {
  const { token, isAuthenticated } = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token && isAuthenticated) {
      const payload = jwtDecode(token);
      setUser(payload);
    } else {
      setUser(null);
    }
  }, [isAuthenticated, token]);

  return <Context.Provider value={{ user }}>{children}</Context.Provider>;
};

export const useUser = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useUser() was called outside a UserProvider`);
  }

  return context;
};
export default UserProvider;
