import axiosInstance from "./axios";

/**
 * @function
 * @param {string} endpoint
 * @param {string} token
 * @param {"backendForFrontend" | "userService" | "contractManagement"} instance
 */
export const fetcher = async (
  endpoint,
  token,
  instance = "backendForFrontend"
) => {
  const res = await axiosInstance[instance].get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};
