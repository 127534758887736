import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography as MuiTypography
  
} from "@mui/material";
import { spacing } from "@mui/system";


import BarChart from "./BarChart";
const Typography = styled(MuiTypography)(spacing);


function Default() {
  const { t } = useTranslation();


  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid container spacing={1}>

        <Grid item xs={12} lg={12} >
          <Typography variant="subtitle1">
          
          {t('LBLCockpitTitle')}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
          <Typography variant="h3" gutterBottom>
          {t('LBLCockpit')}
          </Typography>
          </Grid>
          <Grid item xs={12} lg={12} md={4}>
          <BarChart /> 
        </Grid>
      </Grid>
     
    </React.Fragment>
  );
}
export default Default;
