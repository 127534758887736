import { CardContent, Typography } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next';
import { useCallback } from "react";

function UserInformation({contract}) {

  const { t, i18n } = useTranslation();

  const nutzer = {
    Name:
      contract?.fullName &&
      `${contract?.salutation + " " + contract?.fullName}`,
    Adresse: contract?.address?.street
      ? `${contract?.address?.street} ${contract?.address?.houseNr} ${contract?.address?.postalCode} ${contract?.address?.city} `
      : null,
    BillingOwner:
        contract?.billingAddressOwner ,
    BillingAddress: contract?.billingAddress?.street
        ? `${contract?.billingAddress?.street} ${contract?.billingAddress?.houseNr} ${contract?.billingAddress?.postalCode} ${contract?.billingAddress?.city} `
        : null, 
    Email: contract?.emailAddress,
    PhoneNumber: contract?.phoneNumber,
    BirthDate: contract?.dateOfBirth,

  };

  const renderKey = useCallback((key) => {
    switch (key) {
      case "EMail":
        return "E-Mail";
      case "Kundennummer":
        return t('LBLCustomerNum');
      case "Adresse":
        return t('LBLAddress');
        case "BillingOwner":
        return t('LBLBillingAddressOwner');
      case "BillingAddress":
        return t('LBLBillingAddress'); 
      case "PhoneNumber":
        return t('LBLHandy');
      case "BirthDate":
        return t('LBLBirthDate');      
      default:
        return key;
    }
  }, [contract]);
  return (
    <>
    <View>
        <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          {t('LBLUserInfo')}
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          {nutzer && (
            <>
            {Object.entries(nutzer).map(([key, value])=>{
              return (
                <>
                <Item key={key}>
                  <Typography fontWeight= "fontWeightMedium">
                    {renderKey(key)}
                  </Typography>
                  {key == "Adresse" || key == "BillingAddress" ? (<Typography fontWeight="fontWeightRegular" align="right">
                      {contract?.address?.street} {contract?.address?.houseNr}  <br />
                      {contract?.address?.postalCode} {contract?.address?.city}
                    </Typography>) : (<Typography fontWeight="fontWeightRegular" align="right">
                        {["string", "number"].includes(typeof value)
                          ? value
                          : value?.join(", ") || null}
                      </Typography>)}
                </Item>
                <div style={{  height: "5px"}}></div>
                </>
              )
            })}
            </>
          )}
        </Typography>
        </CardContent>
    </View>
    </>
    
  )
}

export default UserInformation