/**
 * @function
 * @param {number | bigint} number
 * @returns {string} - currency in german format
 */
export const formatCurrency = (number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);
};

/**
 * @function
 * @param {number | bigint} number
 * @returns {string} - number in german format
 */
export const formatDecimal = (number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};
