import { format } from "date-fns";

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const formatDate = (date, formatType) => {
  return format(getUTCDate(date), formatType);
};

export const getErrorResponse = (errorCode) => {
  let message;
  if (errorCode == 'UNAUTHORIZED') {
    {
      message = {
        title: "Falsche Anmeldedaten",
        detail:
          "Sie haben eine falsche E-Mail oder ein falsches Passwort eingegeben. Bitte versuchen Sie es erneut.",
      };
    }
  } else {
    message = {
      title: "Fehler",
      detail:
        "Etwas ist schief gelaufen. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin auftritt, schreiben Sie bitte eine E-Mail an support@my-oli.com",
    };
  }
  return message;
};
