import { useMemo, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, MenuItem, TextField as MuiTextField} from '@mui/material';
import shadows from "@mui/material/styles/shadows";
import Stack from "@mui/material/Stack";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";

function ProductionPlantDetails({ 
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext}) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedPlantTypes, setSelectedPlantTypes] = useState(payload?.plantDetails?.plantType);

  useEffect(() => {
    if (submit > 0) {
      document.getElementById("subTwo").click();}
    setSubmit(0);
  }, [submit]);

 
  const initialValues = {
    plantName: payload?.plantDetails?.plantName || "",
    plantType: payload?.plantDetails?.plantType || "",
    street: payload?.plantDetails?.plantAddress?.street || "",
    houseNr: payload?.plantDetails?.plantAddress?.houseNr || "",
    city: payload?.plantDetails?.plantAddress?.city || "",
    zipCode: payload?.plantDetails?.plantAddress?.postalCode || "",
    installedPowerKw: payload?.plantDetails?.installedPowerKw || "",
    commisioningDate: payload?.plantDetails?.commisioningDate || "" ,
    marketLocation: payload?.plantDetails?.marketLocation || "",
    meterNumber: payload?.plantDetails?.meterNumber || "",
    plantKey: payload?.plantDetails?.plantKey || "",
    operator: payload?.plantDetails?.netOperator || "",
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
          plantName: Yup.string().required(t('LBLPlantNameReq'))
          .label(t('LBLPlantName')),
          plantType:  Yup.string().required(t('LBLplantTypeReq')),
          street: Yup.string().required(t('lblStNameReq'))
          .label(t('lblStName')),
          houseNr: Yup.string().required(t('LBLhouseNrReq'))
          .label(t('LBLHouseNr')),
          city: Yup.string().required(t('LBLCityReq'))
          .label(t('city')),
          zipCode: Yup.number().typeError(t('NumericOnly'))
          .required(t('LBLZipcodeReq')).label(t('PostalCode')),
          installedPowerKw: Yup.number().required(t('LBLinstalledPowerKwReq'))
          .label(t('LBLInstalledOutput')),
          commisioningDate: Yup.string().required(t('LBLcommisioningDateReq'))
          .label(t('LBLCommissioningDate')) ,
          marketLocation: Yup.string().required(t('LBLmarketLocationReq'))
          .label(t('LBLMarketLocation')),
          meterNumber: Yup.string().required(t('LBLmeterNumberReq'))
          .label(t('LBLMeterNumber')),
          plantKey: Yup.string().required(t('LBLplantKeyReq'))
          .label(t('LBLPlantKey')),
          operator: Yup.string().required(t('LBLOperatorReq'))
          .label(t('LBLOperator')),
      }),
    []
  );
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
    plantName: values?.plantName ,
    plantType: values?.plantType,
    plantAddress:{  
      street: values?.street ,
      houseNr: values?.houseNr ,
      city: values?.city ,
      zipCode: values?.zipCode 
    },
    installedPowerKw: values?.installedPowerKw,
    commisioningDate:  format(new Date(values?.commisioningDate), "yyyy-MM-dd"),
    marketLocation: values?.marketLocation ,
    meterNumber: values?.meterNumber ,
    plantKey: values?.plantKey ,
    operator: values?.operator ,
    };

    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);

  };
   
  return (
    <>
       <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
        >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        isValid,
      }) => (
        <Form noValidate >
          <>
            {
            setCanNext(touched && isValid)
            }
              
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="plantName"
                name="plantName"
                label={t('LBLPlantName')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                sx={{ width: '500px' }}
              />
              <MuiTextField
                select
                required
                id="plantType"
                name="plantType"
                open={open}
                fullWidth
                margin="none"
                label={t('LBLPlantType')}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                defaultValue= {payload.plantType}
                value={values.plantType}
                onChange={(event) => setFieldValue("plantType", event.target.value)}
                sx={{ width: '500px' }}
                 >
                      <MenuItem value="HYDRO_POWER">{t('LBLHydroPower')}</MenuItem>
                      <MenuItem value="SOLAR_POWER">{t('LBLSolarPower')}</MenuItem>
                      <MenuItem value="BIO_MASS">{t('LBLBioMass')}</MenuItem>
                      <MenuItem value="WIND_ENERGY">{t('LBLWindEnergy')}</MenuItem>
              </MuiTextField>
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>

              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="street"
                name="street"
                label={t('lblStName')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="houseNr"
                name="houseNr"
                label={t('LBLHouseNr')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="zipCode"
                name="zipCode"
                label={t('PostalCode')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              <Field
                required
                fullWidth
                margin="none"
                component={TextField}
                id="city"
                name="city"
                label={t('city')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="installedPowerKW"
                name="installedPowerKw"
                label={t('LBLInstalledOutput')}
                required
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
                sx={{ width: '500px' }}
               
              />
             <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  
                  <DatePicker
                    label={t('LBLCommissioningDate')}
                    //minDate={dayjs(minStartDate)}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    value={values.commisioningDate}
                    id={"commisioningDate"}
                    onChange={(newValue) => {
                      setFieldValue(
                        "commisioningDate",
                        newValue ? newValue.toDate() : ""
                      );
                    }}
                    
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        required
                        name="commisioningDate"
                        margin="none"
                        autoComplete="off"
                        helperText={touched.commisioningDate && errors.commisioningDate}
                        error={errors.commisioningDate && touched.commisioningDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "TT.MM.JJJJ",
                        }}
                        sx={{ width: '500px' }}
                        
                      />
                    )}
                   
                  />
                 
                </LocalizationProvider>
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="marketLocation"
                name="marketLocation"
                label={t('LBLRegistrationNumber')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
            
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="meterNumber"
                name="meterNumber"
                label={t('LBLMeterNumber')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              </Stack>
               <Stack mb={5} direction="row" spacing={8}>
              <Field
                required
                fullWidth
                margin="none"
                component={TextField}
                id="plantKey"
                name="plantKey"
                label={t('LBLPlantKey')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="operator"
                name="operator"
                label={t('LBLOperator')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              />
              
            </Stack>
            

            <Button sx={{ display: "none" }} id="subTwo" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
    </>
  )
}
ProductionPlantDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ProductionPlantDetails