import axios from "axios";

class AxiosInstanceGroup {
  #userService;
  #contractManagement;

  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
  #createInstance(baseURL) {
    return axios.create({ baseURL });
  }

  /**
   * Get user service API for user authentication
   * @method
   * @returns {import("axios").AxiosInstance}
   */
  get userService() {
    return this.#userService;
  }

  /**
   * @method
   * @param {string} baseURL - base url for instance
   * @returns {import("axios").AxiosInstance}
   */
  set userService(baseURL) {
    this.#userService = this.#createInstance(baseURL);
  }



  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
 
 
  get contractManagement() {
    return this.#contractManagement;
  }

  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
  set contractManagement(baseURL) {
    this.#contractManagement = this.#createInstance(baseURL);
  }



}

export const axiosInstance = new AxiosInstanceGroup();
axiosInstance.userService = window._env_.REACT_APP_USER_SERVICE;
axiosInstance.contractManagement = window._env_.REACT_APP_CONTRACT_SERVICE;

export const userService = axiosInstance.userService;
export const contractManagement =  axiosInstance.contractManagement;

userService.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);





export default axiosInstance;
