import { useMemo, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button , MenuItem , TextField as MuiTextField} from '@mui/material';
import shadows from "@mui/material/styles/shadows";
import Stack from "@mui/material/Stack";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";

function OtherDataDetails({
  payload,
  setPayloadThree,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext
}) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openContractType, setOpenContractType] = useState(false);
  useEffect(() => {
    if (submit > 0) document.getElementById("subThree").click();
    setSubmit(0);
  }, [submit]);

  const initialValues = {
    contractType: payload?.contractType || "",
    contractStatus: payload?.contractStatus|| "",
    startOfDelivery: payload?.startOfDelivery || "",
    salesTaxLiability: payload?.salesTaxLiability || "",
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        contractType: Yup.string().required(t('LBLContractTypeReq'))
          .label(t('LBLContractType')),
        contractStatus: Yup.string().required(t('LBLContractStatusReq'))
          .label(t('LBLUserStatus')),
        startOfDelivery: Yup.date().required(t('LBLDeliveryDateReq'))
          .label(t('LBLStartOfDelivery')),
        salesTaxLiability: Yup.string().required(t('LBLSalesTaxReq'))
          .label(t('LBLSalesTaxLiability')),
      }),
    []
  );
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      contractType: values?.contractType || "",
      contractStatus: values?.contractStatus,
      startOfDelivery:  format(new Date(values?.startOfDelivery), "yyyy-MM-dd") || "",
      salesTaxLiability: values?.salesTaxLiability || "",
      
    };
    setPayloadThree(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  };
   
  return (
    <>
      <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        isValid,
      }) => (
        <Form noValidate >
          <>
            {
            setCanNext(touched && isValid)
            }
              
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <MuiTextField
                select
                id="contractType"
                name="contractType"
                defaultValue={payload?.contractType}
                value={values.contractType}  
                onChange={(event) => setFieldValue("contractType", event.target.value)}
                label={t('LBLContractType')}
                open={openContractType}
                onClose={() => setOpenContractType(false)}
                onOpen={() => setOpenContractType(true)}
                sx={{ width: '500px' }}
              >
                        <MenuItem value="PRODUCER">{t('LBLProducer')}</MenuItem>
                        <MenuItem value="CONSUMER">{t('LBLConsumer')}</MenuItem>
              </MuiTextField>
              
               <MuiTextField
                  select
                  id="contractStatus"
                  name="contractStatus"
                  defaultValue={payload?.contractStatus}
                  value={values.contractStatus}  
                  onChange={(event) => setFieldValue("contractStatus", event.target.value)}
                  label={t('LBLContaractStatus')}
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  sx={{ width: '500px' }}
                >
                          <MenuItem value="ACTIVE">{t('LBLActive')}</MenuItem>
                          <MenuItem value="INACTIVE">{t('LBLExpired')}</MenuItem>
                          <MenuItem value="IN_PROGRESS">{t('LBLInreview')}</MenuItem>
                 </MuiTextField>
                    
            </Stack>
          
            <Stack mb={5} direction="row" spacing={8}>
             <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <div style={{ width: '500px', marginBottom: '10px' }}>
                  <DatePicker
                    label={t('LBLStartOfDelivery')}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    value={values.startOfDelivery}
                    id={"startOfDelivery"}
                    onChange={(newValue) => {
                      setFieldValue(
                        "startOfDelivery",
                        newValue ? newValue.toDate() : ""
                      );
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        required
                        name="startOfDelivery"
                        margin="none"
                        autoComplete="off"
                        helperText={touched.startOfDelivery && errors.startOfDelivery}
                        error={errors.startOfDelivery && touched.startOfDelivery}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "TT.MM.JJJJ",
                        }}
                        sx={{ width: '500px' }}
                      />
                    )}
                  />
                  </div>
                </LocalizationProvider>
                <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="salesTaxLiability"
                name="salesTaxLiability"
                label={t('LBLSalesTaxLiability')}
                InputProps={{
                  title: "Bitte füllen Sie dieses Feld aus",
                }}
              
              />
            </Stack>
            
            <Button sx={{ display: "none" }} id="subThree" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
    </>
  )
}
OtherDataDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadThree: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OtherDataDetails