import * as React from "react";
import { lazy, Suspense, useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Tooltip,
  IconButton as MuiIconButton,
  CircularProgress,
} from "@mui/material";
import { useUser } from "../../services/contexts/UserContext";
import { userService } from "../../services/utils/axios";
import { useAuth } from "../../services/contexts/AuthContext";
import { PowerSettingsNew as PowerIcon } from '@mui/icons-material';
import { Link, useNavigate } from "react-router-dom";
import { getErrorResponse } from "../../services/utils/helpers";
const ErrorFeedback = lazy(() => import("../Feedback"));
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu] = React.useState(null);
  const { authenticate } = useAuth();
  const { user } = useUser();
  const [loggingOut, setLoggingOut] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});

  const navigate = useNavigate();
  const logout = async () => {
    try {
      let access_token = localStorage.getItem('accessToken');
      setLoggingOut(true);
      const response = await userService
        .post(`/logout`, {},
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          });
      const data = response.data?.data;
      setLoggingOut(false)
      if (data != null && data != undefined) {
        localStorage.removeItem("accessToken")
        // no error log out 
        setLoggingOut(false)
        navigate("/login");
      } else {
        let errordata = getErrorResponse(response?.error?.errorCodeResponse)
        setErrorMessage(errordata)
        setHasError(true)
      }

    } catch (error) {
      if (error?.error == "invalid_token") {
        localStorage.removeItem("accessToken")
        // ignore error when token is expired and log out 
        setLoggingOut(false)
        navigate("/login");
      }
      else {
        let errordata = getErrorResponse(error?.error?.errorCodeResponse)
        setErrorMessage(errordata)
        setHasError(true)
      }
      setLoggingOut(false)
    }

  };

  return (
    <React.Fragment>
      <Tooltip title="Abmelden">
        {loggingOut ? (
          <div>
            <CircularProgress size={20} sx={{ color: "action.disabled" }} />
          </div>
        ) : (
          <IconButton
            aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={logout}
            color="inherit"
            size="large"
          >
            <PowerIcon />
          </IconButton>
        )}
      </Tooltip>

      <Suspense fallback={<div />}>
        <ErrorFeedback
          open={hasError}
          severity="warning"
          title={errorMessage?.title}
          message={errorMessage?.detail}
          handleClose={() => {
            setHasError(false)
          }}
        />
      </Suspense>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;