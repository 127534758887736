import { CardContent, Typography } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next';
import { useCallback } from "react";

function BankInformation({contract}) {
    const { t, i18n } = useTranslation();

    const paymentDetails = {
      BankName:
        contract?.paymentDetails?.bankName ,
      Bic: contract?.paymentDetails?.bic ,
      Iban: contract?.paymentDetails?.iban  ,
      AccountHolder: contract?.paymentDetails?.accountHolder,
      RegistrationNumber: contract?.paymentDetails?.registrationNumber  
    };
  
    const renderKey = useCallback((key) => {
      switch (key) {
        case "BankName":
          return t('LBLBankName');
        case "Bic":
          return t('LBLBic');
        case "Iban":
          return t('LBLIban');
        case "AccountHolder":
            return t('LBLAccountHolder');
        case "RegistrationNumber":
            return t('LBLRegistrationNumber');      
        default:
          return key;
      }
    }, [contract]);
    return (
      <>
      <View>
          <CardContent>
          <Typography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLAccountData')}
          </Typography>
          <Spacer mb={4} />
          <Typography variant="body2" component="div" gutterBottom>
            {paymentDetails && (
              <>
              {Object.entries(paymentDetails).map(([key, value])=>{
                return (
                  <>
                  <Item key={key}>
                    <Typography fontWeight= "fontWeightMedium">
                      {renderKey(key)}
                    </Typography>
                    {(<Typography fontWeight="fontWeightRegular" align="right">
                          {["string", "number"].includes(typeof value)
                            ? value
                            : value?.join(", ") || null}
                        </Typography>)}
                  </Item>
                  </>
                )
              })}
              </>
            )}
          </Typography>
          </CardContent>
      </View>
      </>
      
    )
}

export default BankInformation