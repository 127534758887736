import React from "react";
import styled from "styled-components/macro";

import { Grid, Typography } from "@mui/material";

import { grey } from "@mui/material/colors";

import { useUser } from "../../services/contexts";

const Footer = styled.div`
  background-color: ${grey[900]} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooter = ({ ...rest }) => {
  const { user } = useUser();

  return (
    <Footer {...rest}>
      <Grid container spacing={6}>
        <Grid item>
          <FooterText variant="body2">{user?.name}</FooterText>
          <FooterSubText variant="caption">{user?.role_company}</FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
