import { PieChart,PermIdentity } from "@mui/icons-material";
const pagesSection = [
  {
    href: "/cockpit",
    icon: PieChart,
    title: "Cockpit",
  },
   {
    href: "/nutzer",
    icon: PermIdentity,
    title: "Nutzer",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
  
];

export default navItems;
