import { lazy, Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import { CssBaseline, Link, Stack, Typography } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import { Box } from "@mui/system";

const ContactComponent = lazy(() => import("./ContactComponent"));

const Root = styled.div`
  margin: 0 auto;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  position: relative;
  background-color: #fff;
`;

const Auth = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
        <Outlet />
     
        <Box position="absolute" bottom={10} width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            sx={({ breakpoints }) => ({
              px: 2,
              mx: "auto",
              maxWidth: 960,

              [breakpoints.up("sm")]: {
                px: 8,
              },
            })}
          >
            <Link
              color="text.secondary"
              href="https://www.my-oli.com/impressum"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </Link>
            <Link
              color="text.secondary"
              href="https://www.my-oli.com/datenschutz"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz
            </Link>
            <Link
              color="text.secondary"
              href="#"
              aria-describedby="ContactComponent-popover"
              onClick={handleOpen}
            >
              Kontakt
            </Link>
          </Stack>
          <Typography
            align="center"
            variant="body2"
            color="text.secondary"
            sx={{ my: 5 }}
          >
            &copy; {new Date().getFullYear()} OLI Systems GmbH
          </Typography>
        </Box>
      </Root>
      <Suspense fallback={<div />}>
        <ContactComponent anchorEl={anchorEl} handleClose={handleClose} />
      </Suspense>
    </>
  );
};

export default Auth;
