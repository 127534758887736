import Logo from "../../vendor/OLI_Community_Logo.png";
import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";

import ForgetPasswordComponent from "../../components/auth/ForgetPassword";

const ForgetPassword = () => {
  return (
    <>
      <Helmet title="Forget Password" />
      <img src={Logo} alt="logo" width={220} height={60} />
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ mt: 8, mb: 6 }}
        >
          Passwort vergessen
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          align="center"
          sx={{ maxWidth: 720, mx: "auto" }}
          gutterBottom
        >
          Bitte tragen Sie die E-Mail-Adresse ein, welche Sie für Ihr
          Betreiberkonto verwenden. Sie erhalten dann eine E-Mail mit
          Erläuterungen zum Ändern Ihres Passwortes.
        </Typography>
        <ForgetPasswordComponent />
      </Container>
    </>
  );
};

export default ForgetPassword;
