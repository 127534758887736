import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../services/contexts";
import { formatDecimal } from "../../../services/utils/format-numbers";
import api from "../../../Middleware/api/api";

function ConfirmUserDetails({ 
  setRefreshTheData,
  payload,
  openFeedback,
  submit, 
  setError, 
  setSubmit, 
  contract }) {
  const { token } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (submit > 0) 
    updateContractDetails();
    setSubmit(0);
  }, [submit]);
  
  const updateContractDetails = async () => {
    try {
      
      const response = await api.updateContractDetails(
        token,
        payload?.contractKey,
        payload
      );
      setRefreshTheData(true);
        openFeedback();
      
    } catch (error) {
        setError(true);
      }
  };

 
  const renderData = {
    Name: payload?.salutation + " " + payload?.fullName,
    "Address": [
      payload?.address?.street+ " " + payload?.address?.houseNr + ",", 
      payload?.address?.postalCode + " " + payload?.address?.city,
    ],
    "E-Mail": payload?.emailAddress,
    "PhoneNumber": payload?.phoneNumber,
    "DateOfBirth": payload?.dateOfBirth,
    "BillingOwner": payload?.billingAddressOwner,
    "BillingAddress": [
      payload?.billingAddress?.street+ " " + payload?.billingAddress?.houseNr + ",", 
      payload?.billingAddress?.postalCode + " " + payload?.billingAddress?.city,
    ],
    "BankName": payload?.paymentDetails?.bankName,
    "Bic": payload?.paymentDetails?.bic,
    "Iban": payload?.paymentDetails?.iban,
    "AccountHolder": payload?.paymentDetails?.accountHolder,
    "RegistrationNumber": payload?.paymentDetails?.registrationNumber,
    "PlantName": payload?.plantDetails?.plantName ,
    "PowerPlantType": payload?.plantDetails?.plantType ,
    "PowerPlantAddress": [
      payload?.plantDetails?.plantAddress?.postalCode + " " + payload?.plantDetails?.plantAddress?.city,
    ],
    InstalledPowerKw:   formatDecimal(payload?.plantDetails?.installedPowerKw),
    "CommisioningDate": payload?.plantDetails?.commisioningDate  ,
    "MarketLocation": payload?.plantDetails?.marketLocation ,
    "MeterNumber": payload?.plantDetails?.meterNumber ,
    "PlantKey": payload?.plantDetails?.plantKey ,
    "Operator": payload?.plantDetails?.netOperator ,
    "ContractType": payload?.contractType,
    "ContractStatus": payload?.contractStatus,
    "StartofDelivery" : payload?.startOfDelivery,
    "SalesTaxLiability": payload?.salesTaxLiability

  }
  const renderKey = useCallback((key) => {
    switch (key) {
      case "EMail":
        return "E-Mail";
      case "Kundennummer":
        return t('LBLCustomerNum');
      case "Addresse":
        return t('LBLAddress');
      case "PhoneNumber":
        return t('LBLHandy');
      case "DateOfBirth":
        return t('LBLBirthDate');
      case "BillingOwner":
        return t('LBLBillingAddressOwner');
      case "BillingAddress":
        return t('LBLBillingAddress');
        case "BankName":
          return t('LBLBankName');
        case "Bic":
          return t('LBLBic');
        case "Iban":
          return t('LBLIban');
        case "AccountHolder":
            return t('LBLAccountHolder');
        case "RegistrationNumber":
            return t('LBLRegistrationNumber');
        case "PlantName":
          return t('LBLPlantName');
        case "PowerPlantType":
          return t('LBLPlantType');
        case "PowerPlantAddress":
          return t('LBLPlantAdress');
        case "InstalledPowerKw":
          return t('LBLInstalledOutput');
          case "CommisioningDate":
            return t('LBLCommissioningDate');
        case "MarketLocation":
            return t('LBLMarketLocation');
        case "MeterNumber":
            return t('LBLMeterNumber');
        case "PlantKey":
            return t('LBLPlantKey');
        case "Operator":
            return t('LBLOperator');
            case "ContractType":
            return t('LBLContractType');
        case "ContractStatus":
            return t('LBLContaractStatus');
        case "StartofDelivery":
            return t('LBLStartOfDelivery');
        case "SalesTaxLiability":
            return t('LBLSalesTaxLiability');      
      default:
        return key;
    }
  }, [contract]);
   
  return (
    <>
      {Object.entries(renderData).map(([key, value]) => (
        <Stack
          key={key}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={4}
        >

          {value ? (
            <>
              <Typography variant="body1" >
              {renderKey(key)}
              </Typography>
              {typeof value === "string" ? (
                <Typography variant="body1" align="right" id={key}>
                  {value}
                </Typography>
              ) : (
                <Stack my={2}>
                  {value.map((v) => (
                    <Typography variant="body1" align="right" id={v}>
                      {v}
                    </Typography>
                  ))}
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      ))}
    </>
  )
}
ConfirmUserDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any).isRequired,
  openFeedback: PropTypes.func.isRequired,
};

export default ConfirmUserDetails