import { CardContent, Typography, Select, IconButton, Tooltip, MenuItem } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Feedback from '../../../components/Feedback';
import Feedback_Error from "../../../components/DataTable/Feedback";
import { useAuth } from '../../../services/contexts';
import api from '../../../Middleware/api/api';

function OtherData({contract}) {
    const { token } = useAuth();
    const { t, i18n } = useTranslation();
    const [status, setStatus] = useState(contract?.contractStatus);  // Set initial status here
    const [open, setOpen] = useState(false);
    const [tempStatus, setTempStatus] = useState(status);
    const [showIcons, setShowIcons] = useState(false);
    const [successFeedback, setSuccessFeedback] = useState(false);
    const [errorFeedback, setErrorFeedback] = useState(false);

    const handleStatusChange = (event) => {
      setTempStatus(event.target.value);
      setStatus(event.target.value)
      setShowIcons(true);
    };
  
    const handleConfirm = () => {
      setStatus(tempStatus);
      setShowIcons(false); // Close the dropdown after confirmation
    };
  
    const handleClose = () => {
      // Close the dropdown without updating the status
      setShowIcons(false);
      setTempStatus(status); // Reset tempStatus to current status value
      setStatus(contract?.contractStatus)
    };

    const contractDetails = {
      ContractType:
        contract?.contractType ,
      ContractStatus: contract?.contractStatus ,
      StartofContract: contract?.startOfDelivery  ,
      TaxDetails: contract?.salesTaxLiability, 
    };

    //API Call for updating contract status
    const handleUpdateStatus = async () => {
      const contractKey = contract.contractKey;
      try {
        // Call the API method
        const response = await api.updateContractStatus(
          token,
          contractKey,
          tempStatus
        );
        setSuccessFeedback(response.data);
        setStatus(tempStatus);
        setShowIcons(false); 
      } catch (error) {
        setErrorFeedback(true);
      }
    };
   
    const renderKey = useCallback((key) => {
      switch (key) {
        case "ContractType":
          return t('LBLContractType');
        case "ContractStatus":
          return t('LBLUserStatus');
        case "StartofContract":
          return t('LBLStartOfDelivery');
        case "TaxDetails":
            return t('LBLSalesTaxLiability');      
        default:
          return key;
      }
    }, [contract]);
    return (
      <>
      <View>
          <CardContent>
          <Typography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLOtherData')}
          </Typography>
          <Spacer mb={4} />
          <Typography variant="body2" component="div" gutterBottom>
            {contractDetails && (
              <>
              {Object.entries(contractDetails).map(([key, value])=>{
                return (
                  <>
                  <Item key={key}>
                    <Typography fontWeight= "fontWeightMedium">
                      {renderKey(key)}
                    </Typography>
                    { 
                    key === "ContractStatus" ? ( 
                      <>
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <Select
                          id="contractStatus"
                          name="contractStatus"
                          value={status}
                          onChange={handleStatusChange}
                          open={open}
                          onClose={() => setOpen(false)}
                          onOpen={() => setOpen(true)}
                          onClick={() => setShowIcons(true)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                transform: 'translateY(10px)', // Adjust the position of the dropdown
                              },
                            },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                          }}
                        >
                          <MenuItem value="ACTIVE">{t('LBLActive')}</MenuItem>
                          <MenuItem value="INACTIVE">{t('LBLExpired')}</MenuItem>
                          <MenuItem value="IN_PROGRESS">{t('LBLInreview')}</MenuItem>
                    </Select>
                    { showIcons && (
                      <div style={{ display: 'flex' , flexDirection: 'row', marginLeft: '8px'}}>
                        <Tooltip title="Confirm">
                          <IconButton onClick={handleUpdateStatus}>
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton onClick={handleClose}>
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    </div>
                    </>
                     ) : (
                              <Typography fontWeight="fontWeightRegular" align="right">
                                {["string", "number"].includes(typeof value)
                                  ? value
                                  : value?.join(", ") || null}
                              </Typography>
                              )
                              }
                  </Item>
                  <div style={{  height: "5px"}}></div>
                  </>
                )
              })}
              </>
            )}
          </Typography>
          </CardContent>
      </View>
                    <Feedback
                      open={successFeedback}
                      title={t('LBLStatusUpdatedTitle')}
                      message={
                        <>
                          <Typography align="center" >
                            {t('LBLStatusUpdated')}.
                          </Typography>
                        </>
                      }
                      handleClose={()=>setSuccessFeedback(false)}
                    />
                    <Feedback_Error
                      open={errorFeedback}
                      title={t('LBLFailure')}
                      message={
                        <>
                          <Typography align="center">
                            {t('LBLSomethingwentWrong')}
                          </Typography>
                        </>
                      }
                      severity="warning"
                      handleClose={() => {
                        setErrorFeedback(false);
                      }}
                    />
      </>
      
    )
}

export default OtherData