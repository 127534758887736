import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import Stack from "@mui/material/Stack";
import { useAuth } from "../../services/contexts/AuthContext";
import { useTranslation } from "react-i18next";
import {
  Button, 
  Divider as MuiDivider, 
  Grid,  Menu, MenuItem,
  Paper as MuiPaper, 
  ThemeProvider,
  Typography,
  TableCell
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useSWR from "swr";
import { createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import DataTable from "../../components/DataTable";
import { useNavigate } from 'react-router-dom';
import LogoLoader from "../../components/LogoLoader";

var rows = [];

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MuiPaper: {
        variants: [
          {
            props: {
              variant: "elevation",
            },
            style: {
              boxShadow: shadows[3],
            },
          },
        ],
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: 300,
            boxShadow: shadows[3],
            backgroundColor: appTheme.palette.background.paper,
          },
          title: {
            lineHeight: 3,
          },
          header: {
            marginBottom: 0,
          },
          gridListTile: {
            styleOverrides: {
              paddingLeft: "25px",
              marginLeft: "25px",
            },
            sx: {
              pl: 28,
            },
          },

          reset: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: 4,
          },
          resetLink: {
            button: {
              variants: [
                {
                  props: {
                    variant: "outlined",
                  },
                },
              ],
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            paddingLeft: "8px",
            '&[data-colindex="5"], &[data-colindex="8"]': {
              "& > *": {
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              },
              "& .MuiButton-root": {
                margin: "auto",
              },
              '&[data-colindex="4"], &[data-colindex="1"], &[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4", &[data-colindex="6"]': {
                "& > *": {
                  display: "flex",
                  justifyContent: "left",
                  textAlign: "left",
                },
                "& .MuiButton-root": {
                  margin: "auto",
                  paddingLeft: "8px"
                },
              },
              '&[data-colindex="7"]': {
                "& > *": {
                  display: "flex",
                  justifyContent: "right",
                  textAlign: "right",
                },
                "& .MuiButton-root": {
                  margin: "auto",
                },
              },
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "filled",
          margin: "dense",
          size: "medium",
          fullWidth: false,
          sx: {
            ml: 2,
            width: "230px",
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: "dense",
        },
      },
    },
  });
function EnhancedTable() {
  const { t} = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [fetchingData, setFetchingData] = React.useState(true);
  const [operatorContract, setOperatorContract] = React.useState([]);
  const { token } = useAuth();

  const { data } = useSWR(
    token ? ["/users", token, "contractManagement"] : null,
    {
      revalidateOnMount: true,
     
    }
  );

  /////////////////////////////
const navigate = useNavigate();

  const handleDetailsClick = (user) => {
    navigate(`/nutzer/${user.fullName}/`, { state: { user } });
  };
  useEffect(() => {
    if (data) {
      rows = data.data;
      const rowsWithDetail = rows.map((row) => ({
        ...row,
        details: row,
      }));

      setOperatorContract(rowsWithDetail.reverse());

      setFetchingData(false);
    }
  }, [data]);
  useEffect(() => {
    setSelected(
      selectedRows.map((index) => operatorContract[index])
    );
  }, [selectedRows]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [ setIsOpen] = useState(false);

  const [openElem, setOpenElem] = useState(null);
  const [anchorIconButton, setAnchorIconButton] = useState(null);
  const handleCloseIconMenu = () => {
    setOpenElem(null);
    setAnchorIconButton(null);
  };

  const columns = useMemo(
    () => [
      {
        name: "nutzerId",
        options: {
          display: false,
          filter: false,
          customBodyRender: (value) => {
            return value || <>-</>;
          },
        },
      },
      {
        name: "",
        label: t('LBLCustomerNum'),

        options: {
          filter: false,
          customBodyRender: (value) => {
           return value === null ? "" :
            <Typography align="left">{!value ? 1 : value}</Typography>;
          },
          setCellHeaderProps: () => { return { align: "start" } },
          setCellProps: () => { return { align: "left" } },
        },
      },
      
      {
        name: "fullName",
        label: "Name",
        options: {
          filter: false,
          customBodyRender: (value) => {
            return <Typography align="left">{value === null ? "" : value}</Typography>;
          },
          setCellHeaderProps: () => { return { align: "start" } },
          setCellProps: () => { return { align: "left" } },
        },
      },
      {
        name: "address",
        label: t('LBLLocation'),
        options: {
          filter: false,
        
           customBodyRender: (value) => {
           return value === null ? "" : (

              <Typography align="left">{`${value.street} ${value.houseNr}, ${value.postalCode} ${value.city}`}</Typography>
            )
          },
        },
      },
    
      {
        name: "contractType",
        label: t('LBLContractType'),
        options: {
          filter: true,
          filterOptions: {
           
            fullWidth: true,
          },
          customBodyRender: (value) => {
              if (value === null)
              return "";
              else if(value==="PRODUCER")
                return <Typography align="left">{t('LBLProducer')}</Typography>;
              else if(value==="CONSUMER")
                return <Typography align="left">{t('LBLConsumer')}</Typography>;
              else
                return <Typography align="left">{t('LBLProsumer')}</Typography>;
          },
       
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{textAlign: "left",  position: "sticky", top: 0, backgroundColor: "white" }}
            >
              {t('LBLContractType')}
            </TableCell>
          ),
        },
      },
      {
        name: "contractStatus",
       label: t('LBLContractStatus'),
        options: {
          filter: false,
          customBodyRender: (value) => {
            return value === null ? "" : (
               <Typography align="center" style={{ color: 'green' }}>
                      {value === "ACTIVE" ? t('LBLActive') : value === "IN_PROGRESS" ? t('LBLInreview') : t('LBLExpired')}
                </Typography>
            );
          },
          setCellHeaderProps: () => { return { align: "start" } },
          setCellProps: () => { return { align: "left" } },
        },
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{textAlign: "left",  position: "sticky", top: 0, backgroundColor: "white" }}
          >
            {t('LBLContractStatus')}
          </TableCell>
        ),
      },
      {
        name: "details",
        label: " ",
        options: {
          sort: false,
          filter: false,
          customBodyRender(value) {
            return (
              <>
                <Menu
                  anchorEl={anchorIconButton}
                  open={openElem === value}
                  key={value}
                  onClose={handleCloseIconMenu}
                  MenuListProps={{
                    "aria-labelledby": "edit-selected",
                  }}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <MenuItem
                    key={value}
                    onClick={() => {
                        setSelected([value]);
                        setSelectedUser([value])
                        setIsOpen(true);
                        handleCloseIconMenu();
                     
                    }}
                  >
                   
                  </MenuItem>
                </Menu>
                <Button
                  variant="text"
                  onClick={() => handleDetailsClick(value)}
                >
                  Details
                </Button>
              </>
            );
          },
        },
      },
    ],
  );

  return (
    <>
      <ThemeProvider
        theme={(appTheme) => ({
          ...appTheme,
          components: {
            ...appTheme.components,
            ...dataTableTheme(appTheme).components,
          },
        })}
      >
        <Stack
          alignItems="flex-end"
          spacing={8}
          position="relative"
          width={{
            sm: "100%",
            md: "calc(100vw - 260px - 40px)",
            lg: "calc(100vw - 260px - 64px)",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "edit-selected",
            }}
            sx={{
              textAlign: "center",
            }}
          >
       
          </Menu>
          <Paper
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiTableCell-head:not(:nth-of-type(2)) button": {
                mx: 0,
              },
            }}
          >
            {!data ? (<LogoLoader loading={true}/>):(<DataTable
              data={operatorContract}
              columns={columns}
              options={{
                filter: true,
                search: true,
                selectableRows: true
              }}
            />)}
            
          </Paper>
        </Stack>
      </ThemeProvider>

    </>
  );
}

function Users() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => setOpen(false);

  return (
    <React.Fragment>
      <Helmet title="Nutzers" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLUserMgmt')}
          </Typography>
        </Grid>
        <Grid item mb={6}>
          <div>
       <Button variant="contained" color="primary" onClick={openModal}>
              <AddIcon />
             {t('LBLCreateUser')}
            </Button>
          
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default Users;